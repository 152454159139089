import { Dayjs } from 'dayjs';

export enum EVENTS {
  LOGIN_BONUS_2412 = 'login_bonus_2412',
  CHARGE_DOUBLE_2412 = 'charge_double_2412',
  MOON_GREETING_240914 = 'moon_greeting_240914',
  MOON_GREETING_240917 = 'moon_greeting_240917',
  MOON_GREETING_240920 = 'moon_greeting_240920',
  SKETCHUP_PROMOTION = 'sketchup_promotion',
}
export type TEVENTS_PERIOD = {
  [key in EVENTS]: {
    since: Dayjs;
    until: Dayjs;
  };
};
export type TSetterKeys = `set${Capitalize<string & keyof IOnGoingEventState>}`;

export interface IOnGoingEventState {
  onGoingEvents: EVENTS[];
}
export interface IOnGoingEventSetState {
  setOnGoingEvents: (onGoingEvents: EVENTS[]) => void;
}
